// extracted by mini-css-extract-plugin
export var applyBtn = "bG_dG";
export var menuItemClosed = "bG_fv";
export var menuItemOpened = "bG_fs";
export var navClosed = "bG_fr";
export var navContainer = "bG_fp";
export var navOpened = "bG_fq";
export var submenuClosed = "bG_fy";
export var submenuIcon = "bG_ft";
export var submenuItem = "bG_fw";
export var submenuOpened = "bG_fx";